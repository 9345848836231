var jQuery = $;
import slick from '../../node_modules/slick-carousel/slick/slick.min.js';
import marquee from '../../node_modules/jquery.marquee/jquery.marquee.min.js';
import match from '../../node_modules/jquery-match-height/jquery.matchHeight.js';
import fancybox from '../../node_modules/@fancyapps/fancybox/dist/jquery.fancybox.min.js';
import datepicker from '../../node_modules/bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js';
import datepickerNL from '../../node_modules/bootstrap-datepicker/dist/locales/bootstrap-datepicker.nl.min.js';

const app = {
    /**
     * Initialize.
     */
    init() {
        // Initialize sliders
        this.initSliders();

        // Show more function
        this.showMoreBlogs();
        this.showMoreCases();

        // Mobile Navigation
        // this.openNavigation();

        // Sticky MenuCard Bar
        this.stickyMenuCardBar();

        // Smooth scroll
        this.scrollSmooth();

        // File upload check
        this.fileUploadCheck();

        this.initializePopups();

        // PageHeader Video
        this.setCorrectSrc();
        this.pageHeaderMute();

        // Set correct video src for the booking form pageheader
        this.setPageheaderBookingFormVideoSrc();

        this.initializeDatePickers();

        this.toggleFeaturedItems();

        if($(window).width() < 769){
            $('.marquee').marquee({
                duplicated: true,
                gap: 40,
                startVisible: true,
                duration: 20000,
            });
        }

        // Register our event listeners
        $(window).on('scroll', $.proxy(this.windowScrollHandler, this));
        $('.hamburger').on('click', $.proxy(this.toggleHamburgerMenu, this));
        $('a[href="#"]').on('click', $.proxy(this.scrollToAnchor, this));
        $('.overlay, .closebrochurepopup, .closeoffertepopup, .closevacaturepopup').on('click', $.proxy(this.closeModal, this));
        $(".case-overview .image").click(e => $(".folding-menu").toggleClass("active"));
        $(".pageheader.menu .image").click(e => $(".folding-menu").toggleClass("active"));
        $('main .button[href^="#offerte"], main .button[href^="#vacature"]').on('click', $.proxy(this.openModal, this));
        this.prefillPopup();
        // direct browser to top right away
        if (window.location.hash)
            scroll(0, 0);
// takes care of some browsers issue
        setTimeout(function () {
            scroll(0, 0);
        }, 1);
    },

    /**
     * Scroll to anchor.
     *
     * @param {event} e
     */
    scrollToAnchor(e) {
        // $('html, body').animate({scrollTop: $(e.currentTarget).offset().top - 50}, 800, () => window.location.hash = hash);
    },

    /**
     * Handles the scroll event.
     *
     * @param {event} e
     */
    windowScrollHandler(e) {
        $('header').toggleClass('scrolled', $(window).scrollTop() > 45);
    },

    initializePopups() {
        $('a[href^="#reserveren"]').on('click', function (e) {
            let target = $('#sr-res-index');
            if (target.length) {
                e.preventDefault();
                target.click();
            }
        });

        $('a[href^="#reservering-restaurant"]').on('click', function (e) {

            $('body').addClass('is-locked');

            $.fancybox.close();

            e.preventDefault();
            $.fancybox.open({
                src: '#reservering-restaurant',
                touch: false,
                smallBtn: false,
                clickSlide: false,
                openEffect: 'none',
                closeEffect: 'none',
                clickOutside: 'close',
                afterClose: function () {
                    $('body').removeClass('is-locked');
                }
            });
        });

        $('a[href^="#reservering-bar"]').on('click', function (e) {

            $('body').addClass('is-locked');

            $.fancybox.close();

            e.preventDefault();
            $.fancybox.open({
                src: '#reservering-bar',
                touch: false,
                smallBtn: false,
                clickSlide: false,
                openEffect: 'none',
                closeEffect: 'none',
                clickOutside: 'close',
                afterClose: function () {
                    $('body').removeClass('is-locked');
                }
            });
        });

        $('a[href^="#triggerQuotationPopup"]').on('click', function (e) {
            e.preventDefault();
            $.fancybox.open({
                src: '#quotationPopup',
                touch: false,
                smallBtn: false,
                clickSlide: false,
                openEffect: 'none',
                closeEffect: 'none',
                clickOutside: 'close',
            });
        });

        $('.close-popup').click(function () {
            $.fancybox.close();
        });

        $(window).on('load', function () {
            if ($('#infoPopup').length) {
                if (!document.cookie.match(/^(.*;)?\s*info_popup\s*=\s*[^;]+(.*)?$/)) {
                    setTimeout(function () {
                        $.fancybox.open({
                            src: '#infoPopup',
                            touch: false,
                            smallBtn: false,
                            clickSlide: false,
                            openEffect: 'none',
                            closeEffect: 'none',
                            clickOutside: 'close',
                        });

                        var d = new Date();
                        d.setTime(d.getTime() + 3600000 * 24 * 7);
                        var expires = "expires=" + d.toGMTString();
                        var name = 'info_popup';
                        var value = true;

                        document.cookie = name + "=" + value + ";" + expires + ";path-/";
                    }, 5000);
                }
            }
        });
    },

    /**
     * Open hamburger menu.
     *
     * @param {event} e
     */
    toggleHamburgerMenu(e) {
        $(e.currentTarget).toggleClass('is-active');
        $("nav.navigation").toggleClass('visible');
        $("body").toggleClass('is-locked');
    },

    /**
     * Open modal on event.
     *
     * @param {event} e
     */
    openModal(e) {
        $('[data-modal="' + $(e.currentTarget).attr('href') + '"]').addClass('active');
        $('body').addClass('scroll');
    },

    /**
     * Close modal on event.
     *
     * @param {event} e
     */
    closeModal(e) {
        $('.offertepopup, .brochurepopup, .vacaturepopup').removeClass('active');
        $('body').removeClass('scroll');
    },

    /**
     * Prefill popup based on body class.
     *
     */
    prefillPopup() {
        var id = parseInt($('body').attr('class').split(' ')[2].replace('page-id-', ''));
        var options = {
            427: 'Personeelsfeest',
            429: 'Bedrijfsfeest',
            431: 'Walking en seated diners',
            433: 'Seminaries & Presentaties',
            435: 'Productlancering',
            437: 'Modeshows',
            439: 'Event op maat'
        };
        $('#input_5_8').val(options[id]);
    },

    /**
     * Init sliders.
     *
     */
    initSliders() {
        if ($('.logoslider .container').length) {
            $('.logoslider .container').slick({
                autoplay: true, autoplaySpeed: 3000, slidesToShow: 3, mobileFirst: true,
                responsive: [
                    {breakpoint: 768, settings: {slidesToShow: 4}},
                    {breakpoint: 1024, settings: {slidesToShow: 5}},
                    {breakpoint: 1280, settings: {slidesToShow: 6}},
                    {breakpoint: 1440, settings: {slidesToShow: 8}}
                ]
            });
        }
        ;

        if ($('.section-room-detail .image-carousel').length) {
            $('.section-room-detail .image-carousel').slick({
                infinite: false,
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplay: false,
                arrows: true,
                dots: false,
                fade: false,
            });
        }

        // Config image-content slider
        if ($('.image-content-slider .content').length) {
            $('.image-content-slider .content').slick({
                autoplay: false, autoplaySpeed: 3000, slidesToShow: 1,
                fade: true, dots: true, asNavFor: '.image-content-slider .image',
            });
        }
        ;

        if ($('.image-content-slider .image').length) {
            $('.image-content-slider .image').slick({
                autoplay: false, autoplaySpeed: 3000, slidesToShow: 1,
                dots: false, arrows: false, asNavFor: '.image-content-slider .content',
            });
        }
        ;

        // Config image-content-2 slider
        if ($('.slider-image-fixed-content .image').length) {
            $('.slider-image-fixed-content .image').slick({
                autoplay: false, autoplaySpeed: 3000, slidesToShow: 1, dots: true, arrows: true
            });
        }
        ;

        // Config single-post slider
        if ($('.slider .images').length) {
            $('.slider .images').slick({
                autoplay: false, autoplaySpeed: 3000, slidesToShow: 1, dots: true, arrows: true
            });
        }
        ;

        // Config review slider
        if ($('.reviewslider .container.slider').length) {
            $('.reviewslider .container.slider').slick({
                autoplay: false, autoplaySpeed: 3000, slidesToShow: 1, dots: true,
                arrows: false, mobileFirst: true, responsive: [{breakpoint: 768, settings: {slidesToShow: 2}}]
            });
        }
        ;

        // Config review slider
        if ($('.container.slider2').length) {
            $('.container.slider2').slick({
                autoplay: false, autoplaySpeed: 3000, slidesToShow: 1, dots: true,
                arrows: false, mobileFirst: true, responsive: [{breakpoint: 768, settings: {slidesToShow: 1}}]
            });
        }
        ;

        // Config groups slider
        if ($('.group-holder').length) {
            $('.group-holder').slick({
                autoplay: false, slidesToShow: 3, dots: false,
                arrows: true, prevArrow: $('.group-arrow.prev'), nextArrow: $('.group-arrow.next'),
                responsive: [{
                    breakpoint: 768, settings: {
                        slidesToShow: 2, dots: true, arrows: false,
                    }
                }]
            });
        }
        ;

        // Config events slider
        if ($('.events-holder').length) {
            $('.events-holder').slick({
                autoplay: false, slidesToShow: 3, dots: false,
                arrows: true, prevArrow: $('.event-arrow.prev'), nextArrow: $('.event-arrow.next'),
                responsive: [
                    {
                        breakpoint: 1024,
                        settings: {
                            slidesToShow: 2,
                        }
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 1,
                            dots: true,
                            infinite: true,
                        }
                    }]
            });
        }
        ;

        // Hero slider
        if ($('.pagehero .slider').length) {
            $('.pagehero .slider').slick({
                autoplay: true, slidesToShow: 1, dots: true,
                arrows: false, fade: true,
            });
        }
        ;

        // Media slider
        if ($('.media-holder .media .slider').length) {
            $('.media-holder .media .slider').slick({
                autoplay: true, slidesToShow: 1, dots: true,
                arrows: false, fade: true,
            });
        }
        ;

        if ($('.featured-rooms-slider').length) {
            let itemLength = $('.featured-rooms-slider > div').length - 1;
            console.log(itemLength);
            $('.featured-rooms-slider').slick({
                infinite: true,
                slidesToShow: 4,
                slidesToScroll: 1,
                autoplay: false,
                arrows: true,
                dots: false,
                fade: false,
                prevArrow: '.slider-buttons .slider-button-prev',
                nextArrow: '.slider-buttons .slider-button-next',
                responsive: [
                    {
                        breakpoint: 1025,
                        settings: {
                            slidesToShow: 3,
                        },
                    },
                    {
                        breakpoint: 481,
                        settings: {
                            slidesToShow: 1,
                        }
                    }
                ],
            });

            $('.section-featured-rooms .slider-button-next').on('click', function () {
                if ($(this).hasClass('slick-disabled')) {
                    $('.section-featured-rooms').addClass('hidden-fade');
                }
            });

            $('.section-featured-rooms .slider-button-prev').on('click', function () {
                if ($('.section-featured-rooms').hasClass('hidden-fade')) {
                    $('.section-featured-rooms').removeClass('hidden-fade');
                }
            });
        }
    },

    /**
     * Show more function
     *
     */
    showMoreBlogs() {
        $(".loadmoreblogs").on('click', function (e) {
            e.preventDefault();
            $(this).parent().parent().parent().find($('.col:hidden')).slice(0, 3).slideDown().css('display', 'flex');
        });
    },
    showMoreCases() {
        $(".loadmorecases").on('click', function (e) {
            e.preventDefault();
            $(this).parent().parent().parent().find($('.col:hidden')).slice(0, 2).slideDown().css('display', 'flex');
        });
    },

    /** Mobile navigation */
    openNavigation() {

        if ($(window).width() < 1025) {
            $("li.has-children a.navlink").on('click', function (e) {
                e.preventDefault();
                var $this = $(this);

                if ($this.parent().hasClass('is-opened')) {
                    $this.parent().removeClass('is-opened');
                } else {
                    $this.parent().toggleClass('is-opened');
                }
            });

            $('li.has-children ul li a').on('click', function () {
                window.location.href = $(this).attr('href');
            });
        }
    },

    stickyMenuCardBar() {

        $.fn.isInViewport = function () {
            var elementTop = $(this).offset().top;
            var elementBottom = elementTop + $(this).outerHeight();

            var viewportTop = $(window).scrollTop();
            var viewportBottom = viewportTop + $(window).height();

            return elementBottom > viewportTop && elementTop < viewportBottom;
        };

        $(window).on('resize scroll', function () {

            $('.menucard').each(function () {
                var activeMenucard = $(this).attr('id');
                if ($(this).isInViewport()) {
                    $('#item-' + activeMenucard).addClass(activeMenucard + ' is-active');
                } else {
                    $('#item-' + activeMenucard).removeClass(activeMenucard + ' is-active');
                }
            });
        });
    },

    /** Smooth Scroll */
    scrollSmooth() {

        $('.sticky-footer a.item').on('click', function (e) {
            // e.preventDefault();

            var target = this.hash,
                $target = $(target);

            $('html, body').stop().animate({
                'scrollTop': $target.offset().top - 70
            }, 900, 'swing', function () {
                window.location.hash = target;
            });
        });

        $('.navigation a.navlink').on('click', function (e) {

            var target = this.hash,
                $target = $(target);

            $('html, body').stop().animate({
                'scrollTop': $target.offset().top - 70
            }, 900, 'swing', function () {
                window.location.hash = target;
            });
        });

        //smoothscroll navigation
        $(function () {
            if (window.location.hash) {
                $('html,body').animate({
                    scrollTop: $(window.location.hash).offset().top - 70
                }, 900, 'swing');
            }
        });


    },


    fileUploadCheck() {
        $('li.gfield.is-upload input[type="file"]').on('change', function () {

            if ($('input[type="file"]').val()) {
                $("li.gfield.is-upload .gfield_label").toggleClass("has-file");
            }
        });
    },

    /** PageHeader Video */
    setCorrectSrc() {

        if ($(".pagehero:not(.is-contained) .pagehero-video")[0]) {

            if ($('#header-video').data('video-src')) {
                var video_src = $('#header-video').data('video-src');
            } else {
                var video_src = '';
            }

            let variations = {
                480: video_src,
                768: video_src,
                1024: video_src,
                1440: video_src,
                1920: video_src
            };
            for (let key in variations) {
                if ($(window).width() >= 100 && key >= $(window).width() && $(window).width() <= key) {
                    let video = document.getElementsByClassName('videosrc')[0];
                    let source = document.createElement('source');
                    source.setAttribute('src', variations[key]);
                    video.appendChild(source);
                    video.play();
                    break;
                } else if ($(window).width() > 1920) {
                    let video = document.getElementsByClassName('videosrc')[0];
                    let source = document.createElement('source');
                    source.setAttribute('src', variations['1920']);
                    video.appendChild(source);
                    video.play();
                    break;
                }
            }
        }
        ;

        if ($(".pagehero.is-contained .pagehero-video")[0]) {
            let variations2 = {
                480: video_src,
                768: video_src,
                1024: video_src,
                1440: video_src,
                1920: video_src
            };
            for (let key2 in variations2) {
                if ($(window).width() >= 100 && key2 >= $(window).width() && $(window).width() <= key2) {
                    let video2 = document.getElementsByClassName('videosrc2')[0];
                    let source2 = document.createElement('source');
                    source2.setAttribute('src', variations2[key2]);
                    video2.appendChild(source2);
                    video2.play();
                    break;
                } else if ($(window).width() > 1920) {
                    let video2 = document.getElementsByClassName('videosrc2')[0];
                    let source2 = document.createElement('source');
                    source2.setAttribute('src', variations2['1920']);
                    video2.appendChild(source2);
                    video2.play();
                    break;
                }
            }
        }
        ;
    },

    pageHeaderMute() {

        $(".mute-video").click(function () {
            $(".mute-video i").toggleClass('fa-volume-mute');
            $(".mute-video i").toggleClass('fa-volume-up');

            if ($("video").prop('muted')) {
                $('.text').text("Zet geluid uit");
                $("video").prop('muted', false);
                $(this).addClass('unmute-video');

            } else {
                $("video").prop('muted', true);
                $('.text').text("Zet geluid aan");
                $(this).removeClass('unmute-video');
            }
        });
    },

    initializeDatePickers() {
        function GetTodayDate() {
            var tdate = new Date();
            tdate.setDate(tdate.getDate() + 1);
            var dd = tdate.getDate(); //yields day
            var MM = tdate.getMonth(); //yields month
            var yyyy = tdate.getFullYear(); //yields year
            var currentDate = dd + "-" + (MM + 1) + "-" + yyyy;
            return currentDate;
        }

        $('.arrival-date').datepicker({
            autoclose: true,
            format: "dd-mm-yy",
            language: "nl",
            toggleActive: true,
            startDate: GetTodayDate(),
        });

        $('.departure-date').datepicker({
            autoclose: true,
            format: "dd-mm-yy",
            language: "nl",
            toggleActive: true,
            startDate: GetTodayDate(),
        });
    },

    setPageheaderBookingFormVideoSrc()
    {
        if ($(".pageheader-booking-form-video")[0]) {

            var variations = {
                480: $('.pageheader-booking-form-video').data('video-480'),
                768: $('.pageheader-booking-form-video').data('video-768'),
                1024: $('.pageheader-booking-form-video').data('video-1024'),
                1440: $('.pageheader-booking-form-video').data('video-1440'),
                1920: $('.pageheader-booking-form-video').data('video-1920'),
            };

            for (let key in variations) {
                if ($(window).width() >= 100 && key >= $(window).width() && $(window).width() <= key) {
                    var video = document.getElementsByClassName('pageheader-booking-form-videosrc')[0];
                    var source = document.createElement('source');
                    source.setAttribute('src', variations[key]);
                    video.appendChild(source);
                    video.play();
                    break;
                }
                else if ($(window).width() > 1920) {
                    var video = document.getElementsByClassName('pageheader-booking-form-videosrc')[0];
                    var source = document.createElement('source');
                    source.setAttribute('src', variations['1920']);
                    video.appendChild(source);
                    video.play();
                    break;
                }
            }
        }
    },

    toggleFeaturedItems() {

        if ($(window).width() < 769) {

            let allFeaturedItems = $('.features .feature-item .feature-content').hide();

            $('.features .feature-item .feature-title').click(function() {
                let target = $(this).next();

                if(!target.hasClass('is-opened')) {
                    allFeaturedItems.removeClass('is-opened').slideUp();
                    $('.features .feature-item').removeClass('is-opened');
                    $(this).parent().closest('.feature-item').addClass('is-opened');
                    target.addClass('is-opened').slideDown();
                } else {
                    allFeaturedItems.removeClass('is-opened').slideUp();
                    $('.features .feature-item').removeClass('is-opened');
                    target.removeClass('is-opened').slideUp();
                }
                return false;
            });
        }
    }

};

$(document).ready($.proxy(app.init, app));
